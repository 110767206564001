import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import  Layout from '../../components/layout'
import  Seo from '../../components/seo'
import {slugify, GatsbyLink} from "../../components/utils";

import './blog-post.scss'


const BlogPostTemplate = ({ data, location }) => {
  const {
      allContentfulBlogPost
  } = data

    const blog = allContentfulBlogPost.nodes[0]
    // const image = getImage(blog?.heroImage?.gatsbyImageData ?? '');
    const title = blog?.title ?? '';
    const description = blog?.description.description ?? '';
    const body = blog?.body?.childMarkdownRemark?.html ?? '';
    const topics = blog?.topics ?? [];

    const renderTopics = () => {
        return <div className='topic-row'>
            {topics.map( ({name, slug}) => (
                <div className='topic-slug' key={`topic-pill-${slugify(name)}`}><GatsbyLink href={`/blog/${slug}`}>{name}</GatsbyLink></div>
            ))}
        </div>
    }

  return (
      <Layout className='blog-post'>
        <Seo title={title} description={description}/>
        <div className='blog-bg'>
            {/*<GatsbyImage image={image} alt={title} className='main-image' />*/}
            {renderTopics()}
            <h1 className='title'>{title}</h1>
            <div
                dangerouslySetInnerHTML={{
                    __html: body,
                }}
            />
        </div>
      </Layout>
  )
}

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    BlogPostQuery: PropTypes.object
  }),
  location: PropTypes.object
}

export default BlogPostTemplate


export const pageQuery = graphql`
  query BlogBySlug ($slug: String!) {
    allContentfulBlogPost(
        filter: {slug: {eq: $slug}}
    ) {
        nodes {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          description {
            description
          }
          body {
            childMarkdownRemark {
                html
          }
        }
        heroImage {
          gatsbyImageData
        }
        topics {
          name
          slug
        }
      }
    }
  }
`
